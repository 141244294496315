import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import { MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type ValuesType = {
  institution_name: string;
  type: string;
};

type EditableRowProps = {
  initialValues: ValuesType;
  onSubmit: (values: ValuesType) => void;
  onEditChange: () => void;
};

const INSTITUTION_TYPES = [
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
];

const FinancialInsEditRow = ({
  initialValues,
  onSubmit,
  onEditChange,
}: EditableRowProps) => {
  const [values, setValues] = useState<ValuesType>(initialValues);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name as keyof ValuesType]: value,
    }));
  };

  const fields: Array<{ name: keyof ValuesType; placeholder: string }> = [
    { name: 'institution_name', placeholder: 'Institution' },
    { name: 'type', placeholder: 'Type' },
  ];

  const { translate } = useLocales();

  return (
    <TableRow>
      {fields.map((field) => (
        <TableCell key={field.name}>
          {field.name === 'type' ? (
            <Select
              // @ts-ignore
              onChange={handleOnChange}
              name={field.name}
              value={values[field.name]}
              fullWidth
              size="small"
            >
              {INSTITUTION_TYPES?.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {String(
                    translate(`assetsRegister.institutionType.${type.value}`)
                  )}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              size="small"
              placeholder={field.placeholder}
              name={field.name}
              value={values[field.name]}
              onChange={handleOnChange}
              fullWidth
            />
          )}
        </TableCell>
      ))}
      <TableCell>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Tooltip title={String(translate('global.save'))}>
            <IconButton onClick={() => onSubmit(values)}>
              <Iconify icon="eva:save-fill" color="primary.main" />
            </IconButton>
          </Tooltip>
          <Tooltip title={String(translate('global.cancel'))}>
            <IconButton onClick={onEditChange}>
              <Iconify icon="eva:close-fill" color="error.main" />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default FinancialInsEditRow;
