const sortingModel = [
  {
    field: 'description',
    sort_by: {
      asc: 'description',
      desc: '-description',
    },
  },
  {
    field: 'storage_location',
    sort_by: {
      asc: 'storage_location',
      desc: '-storage_location',
    },
  },
  {
    field: 'designated_beneficiary',
    sort_by: {
      asc: 'designated_beneficiary',
      desc: '-designated_beneficiary',
    },
  },
];

export default sortingModel;
