const sortingModel = [
  {
    field: 'institution_name',
    sort_by: {
      asc: 'institution_name',
      desc: '-institution_name',
    },
  },
  {
    field: 'type',
    sort_by: {
      asc: 'type',
      desc: '-type',
    },
  },
];

export default sortingModel;
