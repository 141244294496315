import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React, { useEffect, useState } from 'react';
import { MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import { List, useCommonBeneficiariesList } from '#/api/assetsRegister';

type ValuesType = {
  description: string;
  storage_location: string;
  beneficiary_id: string;
  beneficiary_type?: string;
};

type EditableRowProps = {
  initialValues: ValuesType;
  onSubmit: (values: ValuesType) => void;
  onEditChange: () => void;
};

const AssetsEditRow = ({
  initialValues,
  onSubmit,
  onEditChange,
}: EditableRowProps) => {
  const [beneficiaryType, setBeneficiaryType] = useState('' as string);
  const [values, setValues] = useState<ValuesType>(initialValues);

  const { data: gatheredList } = useCommonBeneficiariesList();

  const getGroups = gatheredList
    ? // @ts-ignore
      Object.keys(gatheredList)?.map((key) => gatheredList[key])
    : [];

  const { translate } = useLocales();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name as keyof ValuesType]: value,
    }));
  };

  const fields: Array<{ name: keyof ValuesType; placeholder: string }> = [
    { name: 'description', placeholder: 'Description' },
    { name: 'storage_location', placeholder: 'Storage Location' },
    { name: 'beneficiary_id', placeholder: 'Designated Beneficiary' },
  ];

  useEffect(() => {
    if (beneficiaryType) {
      setValues((prev) => ({
        ...prev,
        beneficiary_type: beneficiaryType,
      }));
    }
  }, [beneficiaryType]);

  return (
    <TableRow>
      {fields.map((field) => (
        <TableCell key={field.name}>
          {field.name === 'beneficiary_id' ? (
            <Select
              // @ts-ignore
              onChange={handleOnChange}
              name={field.name}
              value={values[field.name]}
              fullWidth
              size="small"
            >
              {getGroups[0]?.map((representative: List) => (
                <MenuItem
                  key={representative.id}
                  value={representative.id}
                  onClick={() => setBeneficiaryType('representative')}
                >
                  {representative.name}
                </MenuItem>
              ))}

              {getGroups[1]?.map((beneficiary: List) => (
                <MenuItem
                  key={beneficiary.id}
                  value={beneficiary.id}
                  onClick={() => setBeneficiaryType('beneficiary')}
                >
                  {beneficiary.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              size="small"
              placeholder={field.placeholder}
              name={field.name}
              value={values[field.name]}
              onChange={handleOnChange}
              fullWidth
            />
          )}
        </TableCell>
      ))}
      <TableCell>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Tooltip title={String(translate('global.save'))}>
            <IconButton onClick={() => onSubmit(values)}>
              <Iconify icon="eva:save-fill" color="primary.main" />
            </IconButton>
          </Tooltip>
          <Tooltip title={String(translate('global.cancel'))}>
            <IconButton onClick={onEditChange}>
              <Iconify icon="eva:close-fill" color="error.main" />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default AssetsEditRow;
