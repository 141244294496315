import { useState } from 'react';
import AddAssetForm from '#/components/pages/AssetsRegister/forms/add-assets/add--asset-form';
import AssetsTable from '#/components/pages/AssetsRegister/tables/assets';

const Assets = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleShowForm = () => setShowForm(true);
  const handleHideForm = () => setShowForm(false);

  return showForm ? (
    <AddAssetForm onClose={handleHideForm} />
  ) : (
    <AssetsTable handleShowForm={handleShowForm} />
  );
};

export default Assets;
