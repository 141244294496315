import { useState } from 'react';
import AddFinancialInstitution from '#/components/pages/AssetsRegister/forms/add-financial-institution/add-financial-institution';
import FinancialInsTable from '#/components/pages/AssetsRegister/tables/financial-institutions';

const FinancialInstitutions = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleShowForm = () => setShowForm(true);
  const handleHideForm = () => setShowForm(false);

  return showForm ? (
    <AddFinancialInstitution onClose={handleHideForm} />
  ) : (
    <FinancialInsTable handleShowForm={handleShowForm} />
  );
};

export default FinancialInstitutions;
