import React, { useCallback, useState } from 'react';
import { Card, Stack, Table, TableBody, TableContainer } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';
import useTable from '#/hooks/useTable';
import Scrollbar from '#/components/shared/Scrollbar';
import AssetsTableToolbar from '#/components/pages/AssetsRegister/tables/assets/assets-table-toolbar';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import AssetsTableRow from '#/components/pages/AssetsRegister/tables/assets/assets-table-row';
import { emptyRows } from '#/components/shared/table/utils';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import TablePaginationCustom from '#/components/shared/table/table-pagination-custom';
import { useGetAssets } from '#/api/assetsRegister';
import useDebounce from '#/hooks/useDebounce';
import sortingModel from '#/components/pages/AssetsRegister/tables/assets/sortingModel';
import ActionButtons from '#/components/pages/AssetsRegister/tables/action-buttons';

type Props = {
  handleShowForm: () => void;
};

const TABLE_HEAD = [
  {
    id: 'description',
    label: 'assetsRegister.assetsTable.tableHeads.description',
  },
  {
    id: 'storage_location',
    label: 'assetsRegister.assetsTable.tableHeads.storageLocation',
  },
  {
    id: 'designated_beneficiary',
    label: 'assetsRegister.assetsTable.tableHeads.designatedBeneficiary',
  },
  {
    id: '',
  },
];

const defaultFilters = {
  search: '',
};
export default function AssetsTable({ handleShowForm }: Props) {
  const [filters, setFilters] = useState(defaultFilters);

  const { state } = useLocation();

  const debouncedSearch = useDebounce(filters.search, 500);
  const table = useTable();
  const hasFilters = filters?.search.length > 0 || debouncedSearch;

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const { data: assetsList } = useGetAssets(
    hasFilters ? 1 : table.page + 1,
    debouncedSearch || undefined,
    table.order ? handleSorting() : undefined,
    state?.ownerID ? state.ownerID : undefined
  );

  const canReset = !isEqual(defaultFilters, filters);
  const notFound =
    (!assetsList?.results?.length && canReset) || !assetsList?.results?.length;

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  return (
    <Card sx={{ width: 1 }}>
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginRight: '20px',
        }}
      >
        <AssetsTableToolbar filters={filters} onFilters={handleFilters} />
        {!state?.ownerID && (
          <ActionButtons
            handleShowForm={handleShowForm}
            buttonLabel="assetsTable.addAsset"
          />
        )}
      </Stack>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar
          styledRootScrollbarSx={{
            height: assetsList?.count === 0 ? '450px' : '300px',
            overflowY: 'scroll',
          }}
        >
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              onSort={table.onSort}
            />
            <TableBody>
              {assetsList?.results?.map((row) => (
                <AssetsTableRow
                  key={row.id}
                  selected={table.selected.includes(String(row.id))}
                  row={row}
                  readOnly={state?.ownerID}
                />
              ))}
              <TableEmptyRows
                height={0}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  assetsList?.results?.length || 0
                )}
              />
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={assetsList?.count || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
