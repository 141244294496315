import { Box, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import Assets from '#/components/pages/AssetsRegister/assets';
import FinancialInstitutions from '#/components/pages/AssetsRegister/financial-institutions';
import {
  invalidateRegisterOfAssets,
  useExportAllData,
} from '#/api/assetsRegister';

export default function AssetsRegister() {
  const [enableDownload, setEnableDownload] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('assets');

  const { translate } = useLocales();
  const { state } = useLocation();

  const TABS = [
    {
      value: 'assets',
      label: String(translate('assetsRegister.tabs.assets')),
      icon: <Iconify icon="ic:round-account-box" />,
      component: <Assets />,
    },
    {
      value: 'financialInstitutions',
      label: String(translate('assetsRegister.tabs.financialInstitutions')),
      icon: <Iconify icon="ic:round-account-box" />,
      component: <FinancialInstitutions />,
    },
  ];

  const { data: exportURL, isLoading: loadingURL } = useExportAllData(
    enableDownload,
    state?.ownerID ? state.ownerID : undefined
  );

  const handleEnableDownload = () => setEnableDownload(true);

  useEffect(() => {
    if (exportURL) {
      window.open(exportURL.file_url, '_blank')?.focus();
      setEnableDownload(false);
      invalidateRegisterOfAssets.exportAllData();
    }
  }, [exportURL]);

  return (
    <Container>
      <Stack
        spacing={2}
        sx={{
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          textAlign="center"
          sx={{
            marginTop: 6,
          }}
        >
          {String(translate('assetsRegister.mainTitle'))}
        </Typography>
        <Typography gutterBottom textAlign="center" color="text.secondary">
          {String(translate('assetsRegister.mainDescription'))}
        </Typography>
        <LoadingButton
          size="small"
          variant="outlined"
          loading={loadingURL}
          onClick={handleEnableDownload}
        >
          <Typography textAlign="center">
            {String(translate('assetsRegister.exportAllData'))}
          </Typography>
        </LoadingButton>
      </Stack>
      <Tabs
        // scrollButtons
        allowScrollButtonsMobile
        variant="scrollable"
        value={currentTab}
        onChange={(_, newValue) => setCurrentTab(newValue)}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      {TABS.map(
        (tab) =>
          tab.value === currentTab && <Box key={tab.value}>{tab.component}</Box>
      )}
    </Container>
  );
}
