import Page from '#/components/shared/ui/Page';
import Assets from '#/components/pages/AssetsRegister';

export default function AssetsRegister() {
  return (
    <Page title="Vermögens- und Sachregister">
      <Assets />
    </Page>
  );
}
