import React, { useCallback, useState } from 'react';
import { Card, Stack, Table, TableBody, TableContainer } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';
import useDebounce from '#/hooks/useDebounce';
import useTable from '#/hooks/useTable';
import Scrollbar from '#/components/shared/Scrollbar';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import { emptyRows } from '#/components/shared/table/utils';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import TablePaginationCustom from '#/components/shared/table/table-pagination-custom';
import FinancialInsTableToolbar from '#/components/pages/AssetsRegister/tables/financial-institutions/financial-ins-table-toolbar';
import FinancialInsTableRow from '#/components/pages/AssetsRegister/tables/financial-institutions/financial-ins-table-row';
import sortingModel from '#/components/pages/AssetsRegister/tables/financial-institutions/sortingModel';
import ActionButtons from '#/components/pages/AssetsRegister/tables/action-buttons';
import { useGetFinancial } from '#/api/assetsRegister';

type Props = {
  handleShowForm: () => void;
};

const TABLE_HEAD = [
  {
    id: 'institution_name',
    label: 'assetsRegister.financialInstitutionsTable.tableHeads.institution',
  },
  {
    id: 'type',
    label: 'assetsRegister.financialInstitutionsTable.tableHeads.type',
  },
  {
    id: '',
  },
];

const defaultFilters = {
  search: '',
};
export default function FinancialInsTable({ handleShowForm }: Props) {
  const [filters, setFilters] = useState(defaultFilters);

  const { state } = useLocation();

  const debouncedSearch = useDebounce(filters.search, 500);
  const hasFilters = filters?.search.length > 0 || debouncedSearch;

  const table = useTable();

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const { data: financialInsList } = useGetFinancial(
    hasFilters ? 1 : table.page + 1,
    debouncedSearch || undefined,
    table.order ? handleSorting() : undefined,
    state?.ownerID ? state.ownerID : undefined
  );

  const canReset = !isEqual(defaultFilters, filters);
  const notFound =
    (!financialInsList?.results?.length && canReset) ||
    !financialInsList?.results?.length;

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  return (
    <Card sx={{ width: 1 }}>
      <Stack
        spacing={2}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginRight: '20px',
        }}
      >
        <FinancialInsTableToolbar filters={filters} onFilters={handleFilters} />
        {!state?.ownerID && (
          <ActionButtons
            handleShowForm={handleShowForm}
            buttonLabel="financialInstitutionsTable.addFinancialInstitution"
          />
        )}
      </Stack>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar
          styledRootScrollbarSx={{
            height: financialInsList?.count === 0 ? '450px' : '300px',
            overflowY: 'scroll',
          }}
        >
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              onSort={table.onSort}
            />
            <TableBody>
              {financialInsList?.results?.map((row) => (
                <FinancialInsTableRow
                  key={row.id}
                  selected={table.selected.includes(String(row.id))}
                  row={row}
                  readOnly={state?.ownerID}
                />
              ))}
              <TableEmptyRows
                height={0}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  financialInsList?.results?.length || 0
                )}
              />
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={financialInsList?.count || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}
