import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import useLocales from '#/hooks/useLocales';

type ActionButtonsProps = {
  handleShowForm: () => void;
  buttonLabel: string;
};

export default function ActionButtons({
  handleShowForm,
  buttonLabel,
}: ActionButtonsProps) {
  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button variant="contained" onClick={handleShowForm}>
        <Typography>
          {String(translate(`assetsRegister.${buttonLabel}`))}
        </Typography>
      </Button>
    </Stack>
  );
}
