import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-hot-toast';
import usePopover from '#/hooks/usePopover';
import Iconify from '#/components/shared/ui/Iconify';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useLocales from '#/hooks/useLocales';
import useBoolean from '#/hooks/useBoolean';
import FinancialInsEditRow from '#/components/pages/AssetsRegister/tables/financial-institutions/financial-ins-edit-row';
import DeleteConfirmation from '#/components/pages/AssetsRegister/tables/financial-institutions/delete-confirmation';
import {
  invalidateRegisterOfAssets,
  useDeleteFinancial,
  useUpdateFinancial,
} from '#/api/assetsRegister';

type Props = {
  selected: boolean;
  row: any;
  readOnly?: boolean;
};

export default function FinancialInsTableRow({
  selected,
  row,
  readOnly,
}: Props) {
  const [enableEdit, setEnableEdit] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleOpenDeleteConfirmation = () => setOpenDeleteConfirmation(true);
  const handleCloseDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const handleEnableEdit = () => setEnableEdit((prev) => !prev);

  const { institution_name, id } = row;
  const { translate } = useLocales();
  const { mutateAsync: updateFinancialInstitution } = useUpdateFinancial(id);
  const { mutateAsync: deleteFinancialInstitution } = useDeleteFinancial(id);

  const popover = usePopover();
  const confirm = useBoolean();

  const onEdit = async (values: any) => {
    try {
      await updateFinancialInstitution(values).then(() => {
        toast.success(
          String(
            translate('toast_notifications.success.updatedFinancialInstitution')
          )
        );
        invalidateRegisterOfAssets.getFinancial();
        handleEnableEdit();
      });
    } catch (error) {
      toast.error(
        String(
          translate('toast_notifications.error.updatedFinancialInstitution')
        )
      );
    }
  };

  const onDelete = async () => {
    try {
      await deleteFinancialInstitution(id).then(() => {
        toast.success(
          String(
            translate('toast_notifications.success.deletedFinancialInstitution')
          )
        );
        invalidateRegisterOfAssets.getFinancial();
        handleCloseDeleteConfirmation();
      });
    } catch (error) {
      toast.error(
        String(
          translate('toast_notifications.error.deletedFinancialInstitution')
        )
      );
    }
  };

  return (
    <>
      {enableEdit ? (
        <FinancialInsEditRow
          initialValues={{
            institution_name,
            type: row.type,
          }}
          onSubmit={onEdit}
          onEditChange={handleEnableEdit}
        />
      ) : (
        <ReadOnlyRow
          row={row}
          selected={selected}
          popover={popover}
          disableOptions={readOnly}
        />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleEnableEdit();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteConfirmation();
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {String(translate('global.delete'))}
        </MenuItem>
      </CustomPopover>
      <DeleteConfirmation
        open={openDeleteConfirmation}
        closeDialog={handleCloseDeleteConfirmation}
        onConfirm={onDelete}
        institution={institution_name}
      />
    </>
  );
}

type ReadOnlyProps = {
  row: any;
  selected: boolean;
  popover: ReturnType<typeof usePopover>;
  disableOptions?: boolean;
  // onSelectRow?: VoidFunction;
};

const ReadOnlyRow = ({
  row,
  selected,
  popover, // onSelectRow,
  disableOptions,
}: ReadOnlyProps) => {
  const { translate } = useLocales();
  const { institution_name, type } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
        {institution_name}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {String(translate(`assetsRegister.institutionType.${type}`))}
      </TableCell>
      {!disableOptions && (
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
